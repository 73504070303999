import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  QuickFacts,
  ImageSlider,
  Phone,
  VideoAndText,
  BannerLinks,
  BannerSmall,
  ProjectPagination,
  HeaderSlider,
  SplitView
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/header.voltadol.jpg'
import imag1 from '../../assets/voltadol.schmerzen.schulter.01.jpg'
import imag2 from '../../assets/apothekenfinder.jpg'
import imag3 from '../../assets/fenistil.checkliste.jpg'
import image4 from '../../assets/bannerlinks/bannerbox.eglo.pim.jpg'
import image5 from '../../assets/bannerlinks/bannerbox.ikb.jpg'
import image8 from '../../assets/projekte/gsk/8.png'
import image9 from '../../assets/projekte/gsk/9.png'
import image10 from '../../assets/projekte/gsk/10.png'
const slides = [
  {
    eachSlide: image8,
    alt: "Checkliste"
  },
  {
    eachSlide: image9,
    alt: "Checkliste"
  },
  {
    eachSlide: image10,
    alt: "Checkliste"
  },
  {
    eachSlide: imag1,
    alt: "Schulterschmerzen Header"
  },
  {
    eachSlide: imag2,
    alt: "Apothekenfinder"
  },
  {
    eachSlide: imag3,
    alt: "Checkliste"
  },
]

const Haleon_Gebro = () => {
  const links = generateBreadcrumbsLinks('/projekte/haleon-gebro', 'Haleon-Gebro')
  return (
    <Layout margin={false} color="white">
      <Helmet
        title="Haleon-Gebro Multidomain-Website"
        meta={[
          {
            name: 'description',
            content:
              'Ob Lamisil, Otrivin, Voltadol, Fenistil, Vitawund oder Chlorhexamed - die Arzneien haben einiges gemeinsam: neben ihrem Erzeuger Haleon-Gebro Consumer Health GmbH dürfen sie nun alle im neuen und individuellen Onlinedesign von holzweg auftreten.'
          },
          {
            name: 'keywords',
            content:
              'Haleon-Gebro holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Haleon, Gebro, Fieberbrunn, Multiportalsystem, Multidomain, Lamisil, Otrivin, Voltadol, Fenistil, Vitawund, Chlorhexamed'
          },
          {
            property: 'og:description',
            content:
              'Ob Lamisil, Otrivin, Voltadol, Fenistil, Vitawund oder Chlorhexamed - die Arzneien haben einiges gemeinsam: neben ihrem Erzeuger Haleon-Gebro Consumer Health GmbH dürfen sie nun alle im neuen und individuellen Onlinedesign von holzweg auftreten.'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/header.voltadol-38c53e5e83521c3ab537b783c16d1c49.jpg'
          }
        ]}
      />
      <BannerSmall image={BannerImage} title="Gesundheit im Griff" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            Haleon-Gebro <br />Multidomain-Website
          </div>
        }
        text={
          <span>
            <p>Ob Lamisil gegen Pilzinfektionen, Otrivin für eine freie Nase, Voltadol zur Schmerzlinderung, Fenistil bei Hautirritationen, Vitawund für die Wundheilung oder Chlorhexamed für die Mundhygiene – diese vielfältigen Arzneimittel eint nicht nur ihre Wirksamkeit, sondern auch ihre gemeinsame Heimat bei der Haleon-Gebro Consumer Health GmbH. Doch das ist nicht alles: Dank der innovativen Gestaltung von holzweg treten sie nun alle in einem neuen, maßgeschneiderten Onlinedesign auf. Jedes Produkt erhält seine eigene Bühne, um seine spezifischen Eigenschaften und Vorteile in den Vordergrund zu stellen. Das harmonische Zusammenspiel von Medizin und Technologie ermöglicht es den Besuchern, sich umfassend zu informieren und das für sie passende Produkt zu entdecken.</p>
            <h3>Links zu den Portalen:</h3>
          </span>
        }
        buttons={[
          { link: 'https://www.haleon-gebro.at/', text: 'Haleon Website' },
          { link: 'https://www.voltadol.at', text: 'Voltadol Website' },
          { link: 'https://www.vitawund.at', text: 'Vitawund Website' },
          { link: 'https://www.chlorhexamed-zahnfleischentzuendung.at', text: 'Chlorhexamed Website' },
          { link: 'https://www.fenistil-juckreiz.at', text: 'Fenistil Website' },
        ]}
      />
      <SplitView
        displayImageLeft={false}
        title="Breites Leistungsspektrum"
        text={
          <>
            <br />
            <p>
            Willkommen zur Haleon-Gebro Multidomain-Website, in Kooperation mit holzweg entwickelt. Hier verbinden sich anspruchsvolle Technik, individuelles Design und vielfältige Portale zu einem gelungenen Online-Erlebnis. Unsere Plattform bietet technische Raffinesse, wie den Apotheken-Finder auf Basis von Echtzeit-Standortdaten und Header-Billboards wie das Voltadol Schmerz-Header. Die Camping-Checkliste ermöglicht personalisierte ToDo-Listen und PDF-Export, während der geschützte Apothekenbereich individuelle Bestellungen ermöglicht. Die Haleon-Gebro Multidomain-Website vereint Technologie und Design für eine bereichernde Nutzererfahrung.
            </p>
            <br />
          </>
        }
        linkText="Mehr Eindrücke entdecken"
        link='#slider'
        backgroundImage={image8}
      />
      <TextBox
        title=""
        text={
          <span>
            <h3>Ein paar Highlights:</h3>
            <ul>
              <li>Mit dem <b>Apotheken-Finder</b> werden auf Basis des aktuellen Standorts Apotheken im Umkreis von 25km auf einer Karte angezeigt.</li>
              <li><b>Individuelle Header-Billboards</b> mit erweiterter Funktion: siehe z.B. Schmerz-Header Voltadol</li>
              <li>Mit der <b>Camping-Checklist</b> können sich User eine Todo-Liste aus bereits vorgeschlagenen Kategorien kreieren und als PDF-Datei exportieren um sich optimal auf ihr bevorstehendes Abenteuer vorzubereiten.</li>
              <li>Auf dem Hauptportal können Apotheken in einem eigenen <b>geschützten Bereich</b> Werbematerialien und weitere Services bestellen.</li>
            </ul>
          </span>
        }
      />
      <HeaderSlider slides={slides} />
      <TextBox title="Weitere Projekte" titleMods={['center']} />
      <ProjectPagination
        titleLeft="Eglo PIM"
        imageLeft={image4}
        leftLink="/projekte/eglo-pim"
        titleRight="IKB Multiportal"
        imageRight={image5}
        rightLink="/projekte/ikb"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Haleon_Gebro
